<template>
  <div class="page__container">
    <Header />
    <Burger />
    <MobileNav />
    <FlexContainer>
      <FlexItem size="25%">
        <Navigation />
      </FlexItem>
      <FlexItem size="75%">
        <div
          class="page"
          :class="$route.meta.background === 'white' && 'u__background--white'"
        >
          <div class="page-header">
            <Content>
              <h1>{{ $route.meta.title }}</h1>
            </Content>
          </div>
          <router-view :key="$route.fullPath" />
        </div>
      </FlexItem>
    </FlexContainer>
  </div>
</template>

<script>
import Navigation from '@/components/header/Navigation'
import Header from '@/components/header/Header'
import MobileNav from '@/components/header/MobileNav'
import Burger from '@/components/header/Burger'

export default {
  components: {
    Navigation,
    Header,
    MobileNav,
    Burger
  }
}
</script>

<style lang="scss" scoped>
.page__container {
  width: 100%;
  position: relative;
}

.page-header {
  background: $color-quaternary;
  height: 32rem;
  display: flex;
  align-items: flex-end;
  padding: $spacing-l;

  h1 {
    font-size: 4.8rem;
    font-weight: 500;
    color: $color-gray--dark;
    margin: 0;
  }

  p {
    color: $color-white;
  }

  @include respond-below(sm) {
    padding: $spacing-m 0;
  }
}
</style>
